//
//  Variables
//  ---------------------------------------------

$polarcore__border-color                    : #ededed !default;
$polarcore__special-price-color             : #FF4444 !default;


$product-name-link__color                   : #232323 !default;
$product-name-link__color__active           : #232323 !default;
$product-name-link__color__hover            : #232323 !default;
$product-name-link__color__visited          : #232323 !default;

$product-name-link__text-decoration         : none !default;
$product-name-link__text-decoration__active : $link__hover__text-decoration !default;
$product-name-link__text-decoration__hover  : $link__hover__text-decoration !default;
$product-name-link__text-decoration__visited: $link__hover__text-decoration !default;

//
//  Product Lists
//  _____________________________________________

.products {
    margin: $indent__l -0.5rem;
}

.product.name a {
    color: #282828 !important;
    font-size: 12px;
    font-weight: bold;
}

a.product-item-link {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    display: inline-block;
    overflow: hidden;
}

a.product-item-link:visited, 
a.product-item-link:hover {
    color: #282828;
}

.product {
    &-items {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
    }
    

    &-item {
        position: relative;
        padding: 1rem;
        border-bottom: 1px solid $polarcore__border-color;

        .products-grid & {
            display: flex;
            width: 100%;
        }

        &-name {
            color: #282828 !important;
            font-size: 12px;
            font-weight: bold;
            display: block;
            word-wrap: break-word;
            hyphens: auto;
            & > a {
                @include lib-link(
                    $_link-color: $product-name-link__color,
                    $_link-text-decoration: $product-name-link__text-decoration,
                    $_link-color-visited: $product-name-link__color__visited,
                    $_link-text-decoration-visited: $product-name-link__text-decoration__visited,
                    $_link-color-hover: $product-name-link__color__hover,
                    $_link-text-decoration-hover: $product-name-link__text-decoration__hover,
                    $_link-color-active: $product-name-link__color__active,
                    $_link-text-decoration-active: $product-name-link__text-decoration__active
                );
            }
        }

        &-info {
            display: flex;
            flex-direction: column;
            max-width: 100%;
            text-align: center;
            .page-products & {
                width: 100%;
            }
        }
        
        &-photo { 
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-actions {
            display: none;
            margin: 1rem 0;
            margin-bottom: 0;

            .actions-secondary {
                display: flex;
                justify-content: center;
                align-items: center;
                & > .action {
                    @extend .abs-actions-addto-gridlist;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    span {
                        @extend .abs-visually-hidden;
                    }
                }
            }
        }

        &-description {
            text-align: left;
            font-size: 12px;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 100%;
            width: 100%;
            overflow: hidden;
            padding-right: 30%;
        }

        .product-reviews-summary {
            width: 100%;

            .rating-summary {
                margin: 0 4px 0 0;
            }

            .reviews-actions {
                display: none;
                font-size: $font-size__s;
                margin-top: 5px;
                text-transform: lowercase;
            }
        }

        .price-box {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;

            .price {
                font-size: 12px;
                font-weight: 500;
                white-space: nowrap;
                padding-left: 0.5rem;
            }

            .price-label {
                display: none;
                font-size: $font-size__s;

                &:after {
                    content: ':';
                }
            }
        }

        .special-price,
        .minimal-price {
            .price {
                color: $polarcore__special-price-color;
            }

            .price-wrapper {
                display: inline-block;
            }

            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .special-price {
            display: block;
        }

        .old-price {
            .price {
                font-weight: $font-weight__regular;
                font-size: 10px;
            }
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .minimal-price-link {
            margin-top: 5px;

            .price-label {
                color: $link__color;
                font-size: 14px;
            }

            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price-link,
        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .price-from,
        .price-to {
            margin: 0;
        }

        .price-to {
            display: none;
        }

        .action.tocompare {
            @include lib-icon-font-symbol($icon-compare-empty);
        }

        .tocart {
            white-space: nowrap;
            width: 100%;
        }
    }
}

.price-container {
    .price {
        font-size: 14px;
    }

    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .price-including-tax + .price-excluding-tax,
    .weee,
    .price-including-tax + .price-excluding-tax .price,
    .weee .price,
    .weee + .price-excluding-tax:before,
    .weee + .price-excluding-tax .price {
        font-size: 11px;
    }

    .weee {
        &:before {
            content: '('attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        + .price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}

.products-list {
    .product {
        &-item {
            display: table;
            width: 100%;

            &-info {
                display: table-row;
            }

            &-photo {
                display: table-cell;
                padding: 0 $indent__l $indent__l 0;
                vertical-align: top;
                width: 1%;
            }

            &-details {
                display: table-cell;
                vertical-align: top;
            }
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .products-list .product {
        &-item {
            table-layout: fixed;

            &-photo {
                padding: 0 $indent__s $indent__s 0;
                width: 30%;
            }
        }
    }
}

@include max-screen($screen__m) {
    .product-items {
        padding: 0;
    }
}

@include min-screen($screen__s) {
    .product {
        &-item {
            .products-grid & {
                margin-bottom: 1rem;
            }

            &-actions {
                display: flex;

                .products-grid & {
                    margin: $indent__s 0;
                    margin-bottom: 0;
                }

                .actions-primary + .actions-secondary {
                    display: flex;
                    white-space: nowrap;
                    & > * {
                        white-space: normal;
                    }
                }

                .actions-primary {
                    display: flex;
                    & > form {
                        width: 100%;
                    }
                }
            }
        }
    }

    .products-grid .product-item {
        width: 33.3333%;
    }

    .page-products,
    .page-layout-1column,
    .page-layout-3columns,
    .page-products.page-layout-1column,
    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: 33.3333%;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-products {
        .products-grid {
            .product-item {
                width: 33.3333%;
            }
        }
    }

    .page-products.page-layout-1column {
        .products-grid {
            .product-item {
                width: calc(33% - 2rem);
                margin: 1rem;
            }
        }
    }

    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: 50%;
            }
        }
    }
}

@include min-screen($screen__l) {
    .products-grid {
        .product-item {
            width: 20%;
        }
    }

    .page-layout-1column {
        .products-grid {
            .product-item {
                width: 16.6666%;
            }
        }
    }

    .page-layout-3columns {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-products {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-products {
        &.page-layout-1column {
            .products-grid {
                .product-item {
                    margin-left: 0;
                    width: calc((100% / 4) - 1rem);
                }
                .products.list.items.product-items .item.product.product-item:nth-of-type(4n) {
                    border-right-width: 0;
                }
            }
        }

        &.page-layout-3columns {
            .products-grid {
                .product-item {
                    width: 33%;
                }
            }
        }
    }
}
